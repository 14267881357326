import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../AuthProvider';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginErrorMsg, setLoginErrorMsg] = useState();
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!username || !password) {
      setLoginErrorMsg('Please fill the Login fields bruh!');
      return;
    }
    setLoading(true);
    
    try {
      const response = await axios.post(`${apiUrl}/api/login/`, { username, password });
      const token = response.data.access;
      const user = response.data.user;
      login(token, user);
      setLoading(false);
      navigate('/', { replace: true });
    } catch (error) {
      const errMsg = (error.response) ? error.response.data.detail : error.toString();
      setLoginErrorMsg(errMsg);
      setLoading(false);
    }
  };

  function forgotPassword() {
    alert('Contact Admin to reset your password');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      document.getElementById('login-button').click();
    }
  };


  return (
    <>
      <Stack spacing={3} style={{marginBottom: '15px'}}>
        <TextField name="username" label="Username" value={username} onChange={(event) => setUsername(event.target.value)}/>
        <TextField
          name="password"
          label="Password"
          value={password}
          onKeyPress={handleKeyPress}
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

     { loginErrorMsg && <Alert severity="error">{loginErrorMsg}</Alert> }

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link variant="subtitle2" underline="hover" onClick={forgotPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        id="login-button"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleLogin}
        loading={loading}
      >
        Login
      </LoadingButton>
    </>
  );
}
