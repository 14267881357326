import axios from 'axios';
import { isNil, isEmpty } from 'lodash';
import { useState, useContext } from 'react';
import { AuthContext } from '../../../sections/auth/AuthProvider';
import { LoadingButton } from '@mui/lab';
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    TextField,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Alert,
    Snackbar,
    Card,
    CardContent,
    Typography,
    CardActions,
    Box,
} from '@mui/material';

import { OrderContext } from 'src/pages/OrderContext';
import CustomerBadgeStatus from 'src/components/customer/CustomerBadgeStatus';
import BillTable from 'src/components/customer/BillTable';
import Iconify from '../../../components/iconify';


/**
 * 
 * You may say: "But...But there's already a QR code scanner Dialog.
 * Why do it twice? Is almost the exact same component
 * This guy doesn't know what he's doing. He's using Context wrong why he's not using Redux. 
 * He's not a Real Reac Developer."
 * But Listen I built this dashboard in one week and I'm super hihg on Aderall
 * So this is the best I can do. Besides I'm the one that forgot to generalize
 * The goddamn QR code scanner Dialog and is already a mess. This is the best I can do.
 * Good look fixing it. 
 * 
 */
export default function OrderScanQrDialog({open, setOpen, groupedProducts}) {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [customer, setCustomer] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [orderBtnLoading, setOrderBtnLoading] = useState(false);

    const { token } = useContext(AuthContext);
    const { orderProducts, clearOrder, productsDescriptions } = useContext(OrderContext); // {id: quantity}

    const CAN_ORDER_STATUS = ['IN_EVENT', "TO_PAY"]

    const handleClose = () => {
        setCustomer({});
        setQrCode('');
        setErrorMsg(null);
        setOrderBtnLoading(false);
        setOpen(false);
    };

    const snackBarClose = () => {
        setOpenSnackBar(false);
    };

    const getCustomerData = async (qrCode) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        return await axios.get(`${apiUrl}/api/customer/?qr-id=${qrCode}`, opt);
    }

    function errorToStringMsg(error) {
        if (error.response) {
            return Object.keys(error.response.data).map((key) => error.response.data[key]);
        }

        return error.toString();
    }

    function resetState() {
        setCustomer({});
        setErrorMsg(null);
        setQrCode('');
    }

    async function searchCustomer(qrCode) {
        const response = await getCustomerData(qrCode);
        const customerArr = response.data;
        const customer = (isEmpty(customerArr)) ? {} : customerArr[0];
        if (!customerCanOrder(customer)) {
            setErrorMsg("El cliente no puede ordenar. Conduzca al Cliente con el administrador en la entrada.")
        }

        setCustomer(customer);
    };

    async function handleQrCodeInputChanged(evt) {
        const qrVal = evt.target.value;
        resetState();
        setQrCode(qrVal.toUpperCase());
        if (qrVal.length < 5) {
            return;
        }

        // Call API
        try {
            await searchCustomer(qrVal);
        } catch (error) {
            const errMsg = errorToStringMsg(error);
            setErrorMsg(errMsg);
            console.error(error);
        }
    };


    function orderProductsToBillObj(orderProducts, searchableProducts) {
        const bill = {
            products: [],
            total: 0,
        };

        for (const [productId, quantity] of Object.entries(orderProducts)) {
            bill.products.push({
                product: searchableProducts[productId],
                quantity,
            });

            bill.total += searchableProducts[productId].price * quantity;
        }

        return bill;
    }

    // For the rare case that the customer is not in the event or banned
    function customerCanOrder(customer) {
        return !isEmpty(customer) && CAN_ORDER_STATUS.includes(customer.status_in_event);
    }

    function constructOrderObj(orderProducts, productsDescriptions, qrCode) {
        const order = {
            products: [],
            customer_qr_id: qrCode,
        };

        for (const [productId, quantity] of Object.entries(orderProducts)) {
            order.products.push({
                id: productId,
                customer_specifics: productsDescriptions[productId],
                quantity,
            });
        }

        return order;
    }

    async function callCreateOrder(orderData) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        return await axios.post(`${apiUrl}/api/customer/create-order`, orderData, opt);
    }

    async function handleOrderBtnClick() {
        setOrderBtnLoading(true);
        const orderData = constructOrderObj(orderProducts, productsDescriptions, qrCode);

        try {
            await callCreateOrder(orderData);
            setSnackBarMsg("Orden creada exitosamente");
            setOpenSnackBar(true);
            clearOrder();
            handleClose();
        } catch (error) {
            const errMsg = errorToStringMsg(error);
            setErrorMsg(errMsg);
            console.error(error);
        }
    }

    return (
        <>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
                <DialogTitle>
                    Escanear QR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Escanea el QR del Cliente
                    </DialogContentText>
                    {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <Stack
                        noValidate
                        autoComplete="off"
                        mt={3}
                        mb={3}
                    >
                        <TextField
                            margin="dense"
                            label="QR CODE ID"
                            type="text"
                            value={qrCode}
                            onChange={handleQrCodeInputChanged}
                            color={!customerCanOrder(customer) ? "error" : "success"}
                            fullWidth
                            autoFocus
                        />
                    </Stack>

                    {(isEmpty(customer) && qrCode.length > 4) && <Stack justifyContent="center" direction="row" mb={3}>
                        <Typography variant='h4' color="text.secondary">{"QR ID NOT FOUND :("}</Typography>
                    </Stack>}

                    <Card sx={{ minWidth: 275, borderColor: "primary.lighter" }} variant="outlined">
                        <CardContent>
                            {!isEmpty(customer) && <Stack direction="row" spacing={1} mb={3}>
                                <Typography variant="h5" component="div">
                                    {customer.full_name}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }}>
                                    {customerCanOrder(customer) || <CustomerBadgeStatus status={customer.status_in_event}></CustomerBadgeStatus>}
                                </Box>
                            </Stack>}
                            <BillTable bill={orderProductsToBillObj(orderProducts, groupedProducts)}></BillTable>

                        </CardContent>
                        <CardActions sx={{ justifyContent: 'center' }}>
                            <LoadingButton
                                loading={orderBtnLoading}
                                loadingPosition="start"
                                disabled={!customerCanOrder(customer)}
                                startIcon={<Iconify icon="material-symbols:check-small" width={35}/>}
                                variant='contained'
                                onClick={handleOrderBtnClick}
                                > Ordenar</LoadingButton>
                        </CardActions>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackBar} onClose={snackBarClose}  autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
                <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%' }}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
            </>
    )
}