import React, { useState, createContext } from 'react';



export const AuthContext = createContext({
  isLoggedIn: false,
  login: () => { },
  logout: () => {},
  user: null,
  token: null,
});

// Define the authentication provider
export function AuthProvider({ children }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoggedIn, setIsLoggedIn] = useState(!!user && !!token);
  
  const login = (token, userData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setToken(token);
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUser(null);
    setToken(null);
  };

  const values = {
    isLoggedIn,
    login,
    logout,
    user,
    token,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
