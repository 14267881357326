import axios from 'axios';
import { isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Typography, Container, Box } from '@mui/material';
import BillTable from '../components/customer/BillTable';
// ----------------------------------------------------------------------
import './glowyCard.css';


const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 0),
}));

// ----------------------------------------------------------------------
function DefaultHome() {
  const [gifUrl, setGifUrl] = useState("");
  
  const getGif = useCallback(async () => {
    const TAGS = ['geometry', 'muppets', '3d', 'pixel art', 'space', 'math', 'physics', 'time lapse', 'cosmos'];
    const url = `https://api.giphy.com/v1/gifs/random`;
    const tag = TAGS[Math.floor(Math.random() * TAGS.length)];

    const opt = {
      params: {
        api_key: "0UTRbFtkMxAplrohufYco5IY74U8hOes",
        tag: tag,
        rating: "g"
      }
    }

    try {
      const response = await axios.get(url, opt);
      setGifUrl(response.data.data.images.original.url);
    } catch (error) {
      console.log(error);
    }

    // axios.get(url, opt).then((response) => { 
    //   setGifUrl(response.data.data.images.original.url);
    // }).catch(error => { console.log(error); });


  }, []);

  useEffect(() => {
    const intervalId = setInterval( async () => { await getGif(); }, 4000);
    return () => clearInterval(intervalId);
  }, [getGif]);

  return (
    <>
      <Box
        component="img"
        src={gifUrl}
        sx={{ height: 700, width: 'auto', my: { xs: 5, sm: 10 } }}
      />
</>
  );
}

function WelcomeView({ customer, setCustomer }) {
  const posibleGifs = [
    'https://media2.giphy.com/media/l0MYGb1LuZ3n7dRnO/giphy.gif',
    'https://media3.giphy.com/media/l41m25GA5xqFRJok0/giphy.gif',
    'https://media0.giphy.com/media/I3EsiEPZWgpqg/giphy.gif',
    'https://media2.giphy.com/media/LmpHsHFG1ZoRy/giphy.gif',
    'https://media1.giphy.com/media/8YyZVHQ7VgEftPbdaC/giphy.gif'
  ];

  const [chosenIndex, setChosenIndex] = useState(Math.floor(Math.random() * posibleGifs.length));
  const [ gifUrl, setGifUrl ] = useState("");

  useEffect(() => {
    setChosenIndex(Math.floor(Math.random() * posibleGifs.length));
    setGifUrl(posibleGifs[chosenIndex]);

    const timerId = setTimeout(() => {
      localStorage.removeItem('customer');
      localStorage.removeItem('bill');
      setCustomer({});
    }, 15000);

    return () => clearTimeout(timerId);
  }, [customer]);

  return (
    <Stack spacing={5}>
      <Typography variant="h1" mt={4} sx={{color: '#34ebe5'}} >
       Welcome {customer.first_name} !
      </Typography>

      <Box
        component="img"
        src={gifUrl}
        sx={{ height: 600, width: 'auto'}}
      />
    </ Stack>
  )
}

function BillView({customer, setCustomer, bill, setBill }) {
  return (
    <Stack spacing={5} sx={{fontSize: '50px'}}>
      <Typography variant="h3" mt={0} sx={{color: '#34ebe5'}} >
        Cuenta:
      </Typography>
      <BillTable bill={bill}/>
    </ Stack>
  )
}

function BillPayedView() {
  const posibleGifs = [
    'https://i.giphy.com/26nfp8HGGHLPGY2KQ.gif',
    'https://i.giphy.com/xULW8v7LtZrgcaGvC0.gif',
    'https://media0.giphy.com/media/l3q2wJsC23ikJg9xe/giphy.gif',
    'https://media0.giphy.com/media/ND6xkVPaj8tHO/giphy.gif',
    'https://media2.giphy.com/media/xT5LMPz2WJVIa31UnC/giphy.gif',
    'https://i.giphy.com/l0ExfNW7JGrk9lezC.gif',
    
  ];

  const [chosenIndex, setChosenIndex] = useState(Math.floor(Math.random() * posibleGifs.length));
  const [ gifUrl, setGifUrl ] = useState("");

  useEffect(() => {
    setChosenIndex(Math.floor(Math.random() * posibleGifs.length));
    setGifUrl(posibleGifs[chosenIndex]);

    const timerId = setTimeout(() => {
      localStorage.removeItem('customer');
      localStorage.removeItem('bill');
      localStorage.removeItem('billPayed');
    }, 10000);

    return () => clearTimeout(timerId);
  }, []);

  return (
    <Stack spacing={5}>
      <Typography variant="h1" mt={4} sx={{color: '#34ebe5'}} >
       ¡ Gracias ! 
      </Typography>
      <Box
        component="img"
        src={gifUrl}
        sx={{ height: 600, width: 'auto'}}
      />
    </ Stack>
  )
}


export default function SecondScreen() {
  const [customer, setCustomer] = useState({});
  const [bill , setBill] = useState({});
  const [billPayed, setBillPayed] = useState(false);

  function resetState() {
    setCustomer({});
    setBill({});
  }

  const checkLocalStorage = useCallback(async () => {
    const customerStr = localStorage.getItem('customer') || "{}";
    const billStr = localStorage.getItem('bill') || "{}";
    const billPayedStr = localStorage.getItem('billPayed', 'false');
    
    const newCustomer = JSON.parse(customerStr);
    const newBill = JSON.parse(billStr);
    const billPayed = JSON.parse(billPayedStr);
    
    if (isEmpty(customer) || (!isEmpty(customer) && customer.id !== newCustomer.id)) {
      setCustomer(newCustomer);
      setBill(newBill);
      setBillPayed(billPayed);
    }
  }, [customer]);

  useEffect(() => {
    const intervalId = setInterval( () => { checkLocalStorage(); }, 500);
    return () => clearInterval(intervalId);
  }, [checkLocalStorage, customer, billPayed]);

  return (
    <>
      <Helmet>
        <title> Tripu View </title>
      </Helmet>
      <Container id="second-screen-container">
        {(!isEmpty(customer) && !isEmpty(bill)) && <Stack mt={10}>
           <BillView customer={customer} setCustomer={setCustomer} bill={bill} setBill={setBill} />
        </Stack>}


        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          
          { billPayed && <BillPayedView /> }
          {(isEmpty(customer) && !billPayed) && <DefaultHome /> }
          { (!isEmpty(customer) && isEmpty(bill)) && <WelcomeView customer={customer} setCustomer={setCustomer}/> }
        </StyledContent>
        
      </Container>

    </>
  );
}
