import React, { useState, createContext } from 'react';


export const OrderContext = createContext({
  orderProducts: {},
  productsDescriptions: {}, // {product_id: description}
  customerQrCode: null,
  setOrder: () => {},
  addProduct: () => { },
  substractProduct: () => { },
  clearOrder: () => {},
  addCustomerSpecifics: () => {}
});

// Define the authentication provider
export function OrderProvider({ children }) {
  const [orderProducts, setOrderProducts] = useState({}); // {product_id: count}
  const [customerQrCode, setCustomerQrCode] = useState(null);
  const [productsDescriptions, setProductsDescriptions] = useState({}); // {product_id: description
  
  function addProduct(id) {
    const newProducts = { ...orderProducts };
    newProducts[id] = (id in newProducts) ? newProducts[id] + 1 : 1;
    setOrderProducts(newProducts);
  }

  function addCustomerSpecifics(id, customerSpecifics) {
    const newProductsDescriptions = { ...productsDescriptions };
    newProductsDescriptions[id] = customerSpecifics;
    setProductsDescriptions(newProductsDescriptions);
  }

  function substractProduct(id) {
    const newProducts = { ...orderProducts };
    if (id in newProducts && newProducts[id] === 1) {
      delete newProducts[id];
    } else {
      newProducts[id] = newProducts[id] - 1;
    }

    setOrderProducts(newProducts);
  }

  function clearOrder() {
    setOrderProducts({});
    setCustomerQrCode(null);
  }

  const values = {
    orderProducts,
    customerQrCode,
    addProduct,
    substractProduct,
    clearOrder,
    addCustomerSpecifics,
    productsDescriptions
  };

  return <OrderContext.Provider value={values}>{children}</OrderContext.Provider>;
}
