import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import {
  Typography,
  Container,
  Box,
  Stack,
  Alert,
  TextField,
  Button,
  Grid
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../components/iconify/Iconify';
import APIClient from '../utils/APIClient';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function ReferralForm() {
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const Form = () => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [areaCode, setAreaCode] = useState('+52');
    const [disableSendBtn, setDisableSendBtn] = useState(true);
    const [sendBtnLoading, setSendBtnLoading] = useState(false);
    const [referrerName, setReferrerName] = useState('');

    const apiClient = new APIClient();
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referral-code');

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
          document.getElementById('save-btn').click();
      }
    };
    
    function errorToStringMsg(error) {
      if (error.response) {
          return Object.keys(error.response.data).map((key) => error.response.data[key]);
      }

      return error.toString();
    }

    const handleSave = async () => {
      setSendBtnLoading(true);
      const data = {
        phone_number: `${areaCode}${phoneNumber.replace(/\s/g, '')}`,
        first_name: firstName,
        last_name: lastName,
        referrer_code: referralCode
      };

      console.log(data);

      apiClient.post('/api/referral/create-customer', data).then((response) => {
        setFormSubmitted(true);
        setSendBtnLoading(false);
      }).catch((error) => {
        const errMsg = errorToStringMsg(error);
        setErrorMsg(errMsg);
        console.error(error);
        setSendBtnLoading(false);
      });
    };
  
    const handleAreaCodeChange = (event) => {
      const areaCodeVal = event.target.value;
      setAreaCode(areaCodeVal);
      if (!areaCodeVal.startsWith('+')) {
        setAreaCode("+" + areaCodeVal);
        return;
      }
    };
  
    function isvalidPhoneNumber(phoneNum) {
      const regex = /^([0-9]{7,15})$/;
      return regex.test(phoneNum);
    }

    function handlePhoneNumberChange(event) {
      const phoneNumberVal = event.target.value;
      setPhoneNumber(phoneNumberVal);

      const cleanedPhoneNum = phoneNumberVal.replace(/\s/g, '');
      if (isvalidPhoneNumber(cleanedPhoneNum)) {
        setInvalidPhoneNumber(false);
      } else {
        setInvalidPhoneNumber(true);
      }
    };

    useEffect(() => {
      if (firstName && lastName && isvalidPhoneNumber(phoneNumber.replace(/\s/g, '')) && (areaCode.length > 1)) {
        setDisableSendBtn(false);
      } else {
        setDisableSendBtn(true);
      }
    }, [firstName, lastName, phoneNumber, areaCode]);
    
    useEffect(() => {
      apiClient.get(`/api/referral/referrer-name?referralCode=${referralCode}`)
        .then((response) => {
          setReferrerName(response.data.name);
        }).catch((error) => {
          setInvalidUrl(true);
          console.log(error);
        });
    }, []);

    return (
      <>
        <Grid item sx={{ textAlign: 'center', alignItems: 'center'}} mt={10} xs={12} sm={10} md={8} lg={6} >
            <Typography variant="h4">
            ¡ {referrerName} te ha invitado a un fiesta !
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Registrate y en breve te contactaremos por whatsapp con más detalles./
              Register and we will contact you soon via whatsapp with more details.
            </Typography>

            <Box mt={5} p={0}>
              {errorMsg && (
                <Stack mb={3}>
                  <Alert severity="error">
                    {errorMsg}
                  </Alert>
                </Stack>
              )}
              <Stack
                  direction="row"
                  noValidate
                  spacing={2}
                  mb={2}
                  autoComplete="off"
              >
                  <TextField
                      label="Nombre / Name"
                      fullWidth
                      type="text"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                      autoFocus
                  />

                  <TextField
                      margin="dense"
                      label="Apellido / Last Name"
                      fullWidth
                      type="text"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                  />
                  
              </Stack>
              <Grid container spacing={2} p={0} mt={1}>
                <Grid item xs={3}>
                <TextField
                  label="Area Code"
                  placeholder='+123'
                  type="text"
                  fullWidth
                  value={areaCode}
                  onChange={handleAreaCodeChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                      label="Telefono / Phone Number"
                      placeholder='55 1234 5678'
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <Iconify color="grey" width={30} mr={1} icon="ic:baseline-whatsapp" />
                        ),
                      }}
                      error={invalidPhoneNumber}
                      fullWidth
                      value={phoneNumber}
                      onKeyPress={handleKeyPress}
                      onChange={handlePhoneNumberChange}
                  />
                </Grid>
                  
              </Grid>
              <Stack mt={2}>
                <LoadingButton
                  loading={sendBtnLoading}
                  loadingPosition="center"
                  onClick={handleSave}
                  disabled={disableSendBtn}
                  variant='contained'
                  size="large"
                  id="save-btn"
                  startIcon={<Iconify width={25} icon="ic:sharp-send" />} 
                >
                  Enviar
                </LoadingButton>
              </Stack>
            </Box>
        </Grid>
      </>
    )
  }

  const InavlidView = () => {
    return (
      <>
         <Grid item sx={{ textAlign: 'center', alignItems: 'center'}} mt={10} xs={12} sm={10} md={8} lg={6} >
            <Typography variant="h4">
              Url Inválido. Por favor, contacta a tu invitador. / Invalid URL. Please contact your inviter.
            </Typography>

            <Box
              component="img"
              mt={5}
              src="https://media4.giphy.com/media/owRSsSHHoVYFa/giphy.gif?cid=ecf05e47qg1ecf2p88wntzw7vc6nylbfd1emmxxk6h5jj6tb&rid=giphy.gif&ct=g"
              sx={{ height: 700, width: 'auto'}}
            />
          </Grid>
      </>
    )
  };

  const FullPartyView = () => {
    return (
      <>
         <Grid item sx={{ textAlign: 'center', alignItems: 'center'}} mt={10} xs={12} sm={10} md={8} lg={6} >
            <Typography variant="h4">
              ¡ Lo sentimos ! Esta fiesta ya está llena. / Sorry, This party is already full.
            </Typography>

            <Box
              component="img"
              mt={5}
              src="https://media0.giphy.com/media/l2TO0YJGy8KwhyHfkr/giphy.gif"
              sx={{ height: 700, width: 'auto'}}
            />
          </Grid>
      </>
    )
  };

  const SentView = () => {
    return (
      <>
         <Grid item sx={{ textAlign: 'center', alignItems: 'center'}} mt={10} xs={12} sm={10} md={8} lg={6} >
            <Typography variant="h5">
              ¡ Gracias por registrarte. Te contactaremos pronto ! / Thanks for signing up. We will contact you soon. !
            </Typography>

            <Box
              component="img"
              mt={5}
              src="https://media4.giphy.com/media/1NVtSIcr1PQnf7X2kG/giphy.gif?cid=ecf05e47d66v8fztsu5d3uwgplujjojgrejt4edrdymoprs7&rid=giphy.gif&ct=g"
              sx={{ height: 500, width: 'auto'}}
            />
          </Grid>
      </>
    )
  };


  return (
    <>
      <Helmet>
        <title> Referral </title>
      </Helmet>

      <Container>
        <Grid container >
          {/* PADDING */}
          <Grid item xs={0} sm={1} md={2} lg={3}/>

          {/* TODO: DELETE */}
          <FullPartyView /> 
          {/* {(!invalidUrl && !formSubmitted) && <Form />}
          {(invalidUrl && !formSubmitted) && <InavlidView />}
          {formSubmitted && <SentView />} */}

          {/* PADDING */}
          <Grid item xs={0} sm={1} md={2} lg={3} />
        </Grid>
      </Container>
    </>
  );
}
