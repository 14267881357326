import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import APIClient from '../utils/APIClient';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { AuthContext } from '../sections/auth/AuthProvider';
import QrCodeImageDialog from '../components/customer/QrCodeImageDialog';
import NewCustomerDialogForm from '../components/customer/NewCustomerDialogForm';
import ScanQrCodeDialog from '../components/customer/ScanQrCodeDialog';
import CustomerBadgeStatus from '../components/customer/CustomerBadgeStatus';
import CustomerStatus from '../components/customer/CustomerStatus';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phoneNumber', label: 'Phone Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'qrCode', label: 'QR Code', alignRight: false },
  // { id: '' },  // for the action buttons
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CustomerPage() {
  const [open, setOpen] = useState(null);

  const [openQrCodeImageDialog, setOpenQrCodeImageDialog] = useState(false);

  const [qrCodeSelectedForDialog, setQrCodeSelectedForDialog] = useState("");

  const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false);

  const [openScanQrCodeDialog, setOpenScanQrCodeDialog] = useState(false);

  const [invitationSent, setInvitationSent] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('fullName');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { token } = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);

  const apiCLient = new APIClient(token);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  
  const handleOpenQrCodeImageDialog = (qrCode, invitationSent) => {
    setOpenQrCodeImageDialog(true);
    setQrCodeSelectedForDialog(qrCode);
    setInvitationSent(invitationSent);
  };

  function inviteAllCustomers() {
    const data =  { "customer_ids": customers.map((customer) => customer.id) };

    apiCLient.post("/api/customer/invite-customers", data).then((response) => {
      getCustomers();
    }).catch((error) => {
      alert("Error conectandose con el Servidor. Haz Logout y login de nuevo.")
      console.log(error);
    });
  }

  function handleIndividualInvite(id) {
    const data =  { "customer_ids": [id] };

    apiCLient.post("/api/customer/invite-customers", data).then((response) => {
      getCustomers();
    }).catch((error) => {
      alert("Error conectandose con el Servidor. Haz Logout y login de nuevo.")
      console.log(error);
    });
  }

  function getCustomers() {
    apiCLient.get("/api/customer/").then((response) => {
      setCustomers(response.data || []);
    }).catch((error) => {
      alert("Error conectandose con el Servidor. Haz Logout y login de nuevo.")
      console.log(error);
    });
  }

  function handleInviteAllBtn() {
    const confirm = window.confirm("Are you sure you want to invite all customers?");

    if (confirm) {
      inviteAllCustomers();
    }
  }

  useEffect(() => {
    getCustomers();
  }, [openNewCustomerDialog, openScanQrCodeDialog, token]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customers.length) : 0;

  const filteredUsers = applySortFilter(customers, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Customer </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack>
            <Typography variant="h4" gutterBottom>
              Customer
            </Typography>
            <Link to='/second-screen' target='_blank' style={{color: "blue"}}>
              Second Screen <Iconify icon="eva:external-link-outline" />
            </Link>
          </Stack>
          <Stack direction="row" spacing={1}>
          <Button variant="outlined" startIcon={<Iconify icon="material-symbols:done-all" />} onClick={handleInviteAllBtn}>
              Invite All Customers
            </Button>
            <Button variant="contained" startIcon={<Iconify icon="material-symbols:qr-code-scanner" />} onClick={() => setOpenScanQrCodeDialog(true)}>
              Scan QR
            </Button>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setOpenNewCustomerDialog(true)}>
              New Customer
            </Button>
          </Stack>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={customers.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      full_name: fullName,
                      status_in_event: statusInEvent,
                      phone_number: phoneNumber,
                      qr_id: qrId,
                      is_referral: isReferral,
                      invitation_sent: invitationSent,
                      referrer_customer_name_and_qr_id: referrerCustomerNameAndQrId,
                    } = row;

                    return (
                      <TableRow  key={id}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={fullName} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {fullName}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                        <Link to={`https://api.whatsapp.com/send?phone=${phoneNumber.replace("+", "")}`} target='_blank'>
                          {phoneNumber}
                        </Link>
                        </TableCell>

                        <TableCell align="left">
                          <Stack width={'fit-content'} spacing={1}>
                            <CustomerBadgeStatus status={statusInEvent} />
                            {!invitationSent && <CustomerBadgeStatus status={"INVITATION_PENDING"} />}
                            {isReferral && <CustomerBadgeStatus status={`Referral: ${referrerCustomerNameAndQrId}`} />}
                          </Stack>            
                        </TableCell>
                        
                        <TableCell align="left">
                          {(statusInEvent !== CustomerStatus.NOT_INVITED) && 
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<Iconify icon="material-symbols:qr-code-2" />}
                              onClick={() => { handleOpenQrCodeImageDialog(qrId, invitationSent) }}
                            >
                              {qrId}
                            </Button>
                          }

                          {(statusInEvent === CustomerStatus.NOT_INVITED) && 
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Iconify icon="material-symbols:event-available" />}
                              onClick={() => { handleIndividualInvite(id) }}
                            >
                             Set as Invited
                            </Button>
                          }
                        </TableCell>

                        {/* <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
      </Popover> */}

      <QrCodeImageDialog
        open={openQrCodeImageDialog}
        setOpen={setOpenQrCodeImageDialog}
        qrId={qrCodeSelectedForDialog}
        invitationSent={invitationSent}
        setInvitationSent={setInvitationSent}
      />

      <NewCustomerDialogForm open={openNewCustomerDialog}  setOpen={setOpenNewCustomerDialog} />
      <ScanQrCodeDialog open={openScanQrCodeDialog} setOpen={setOpenScanQrCodeDialog} />
    </>
  );
}
