import './darkScrollbar.css'
import { useState, useContext } from 'react';
// @mui
import {
  Card,
  Stack,
  Divider,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Label from 'src/components/label';
import { AuthContext } from 'src/sections/auth/AuthProvider';

// components
import Iconify from '../../../components/iconify';
import {ORDER_STATUS, ORDER_STATUS_LABEL} from './OrderStatusEnum';

function getBorderColor(status, theme) {
  switch (status) {
    case ORDER_STATUS.PENDING:
      return theme.palette.warning.dark;
    case ORDER_STATUS.IN_PROGRESS:
      return theme.palette.info.darker;
    case ORDER_STATUS.COMPLETED:
      return theme.palette.success.darker;
    case ORDER_STATUS.DELIVERED:
      return theme.palette.tertiary.dark;
    case ORDER_STATUS.CANCELLED:
      return theme.palette.error.darker;
    default:
      return theme.palette.info.darker;
  }
}


export default function OrderTasksCard({order, updateOrderStatus, cancelOrder, completeOrder, deliverOrder }) {
  const theme = useTheme();
  const [ orderState, setOrderState ] = useState(order);
  const borderColor = getBorderColor(orderState.status, theme);
  const { user } = useContext(AuthContext);
  
  
  function isStaff(user) {
    return user && (user.is_admin || user.is_staff);
  }

  const getLabelColor = (status) => {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return 'warning';
      case ORDER_STATUS.IN_PROGRESS:
        return 'info';
      case ORDER_STATUS.DELIVERED:
        return 'tertiary';
      case ORDER_STATUS.COMPLETED:
        return 'success';
      case ORDER_STATUS.CANCELLED:
        return 'error';
      default:
        return 'info';
    }
  }

  // function handleCancelBtnClick(orderId) {
  //   // eslint-disable-next-line no-restricted-globals
  //   const question = confirm(`¿Está seguro que desea cancelar la orden #${orderId}?`);
  
  //   if (question) {
  //     cancelOrderHandler(orderId);
  //   }
  // }

  async function handleCardClick() {
      if (orderStatus === ORDER_STATUS.PENDING) {
        setOrderState({...orderState, status: ORDER_STATUS.IN_PROGRESS});
        await updateOrderStatus(order.id, ORDER_STATUS.IN_PROGRESS);
      }
  }

  const { id, customer: customerName, order_products: orderProducts, status: orderStatus, order_category: category } = orderState;
  
  return (
    <Card 
      key={id}
      className={(orderStatus === ORDER_STATUS.PENDING) ? 'glow-border' : 'no-glow'}
      onClick={handleCardClick}
      sx={{border: `2px solid ${borderColor}`, minWidth: '350px'}} 
    >
      <CardHeader 
        title={`Order #${order.id}`}
        subheader={customerName}
        sx={{marginBottom: "15px"}}
        action={
          <Stack direction="row" spacing={1}>
            <Label color={getLabelColor(orderStatus)}>
              <Typography variant="body1">{ORDER_STATUS_LABEL[orderStatus]}</Typography>
            </Label>
            <Label color="info">
              <Typography variant="body1">{category}</Typography>
            </Label>
          </Stack>
        }
      >
        
      </CardHeader>
      <Divider />
      <CardContent sx={{minHeight: "150px", maxHeight: "150px", overflow: 'auto'}}>
        <Typography variant='body1' color="text.secondary" gutterBottom>{orderProducts.length} productos</Typography>
          {orderProducts.map((orderProd) => {         
            return (
              <TaskItem
                taskId={`${order.id}-${orderProd.product.id}`}
                key={`${order.id}-${orderProd.product.id}`}
                status={orderStatus}
                productName={orderProd.product.product_name}
                quantity={orderProd.quantity}
                specifics={orderProd.customer_specifics}
                textSize={15}
              />
            )})}
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'center', padding: '20px 20px' }}>
        <Stack direction="row" spacing={4}>
            {(order.status === ORDER_STATUS.CANCELLED || !isStaff(user)) ||
              <Button
                startIcon={<Iconify icon="material-symbols:cancel" />}
                size="medium"
                variant='outlined' color="warning"
                onClick={(evt) => {
                  evt.stopPropagation();
                  cancelOrder(order.id);
                }}
              >
                  Cancelar
              </Button>
            }

            {([ORDER_STATUS.IN_PROGRESS, ORDER_STATUS.PENDING].includes(order.status) && isStaff(user)) && 
            
              <Button
                startIcon={<Iconify icon="material-symbols:check" />}
                size="medium"
                variant='outlined'
                color="success"
                onClick={(evt) => {
                  evt.stopPropagation();
                  completeOrder(order.id);
                }}
              >
                Completar
              </Button>
            }

          {(order.status === ORDER_STATUS.COMPLETED) && 
            <Button
              startIcon={<Iconify icon="icon-park-outline:delivery" />}
              size="medium"
              variant='outlined'
              color="success"
              onClick={(evt) => {
                evt.stopPropagation();
                deliverOrder(order.id);
              }}
            >
              Entregada
            </Button>
          }

        </Stack>
      </CardActions>
    </Card>
  );
}

// ----------------------------------------------------------------------
function TaskItem({ taskId, productName, textSize, quantity, status, specifics, checked, onChange }) {
  return (
    <Stack
      sx={{
        ...(checked && {
          color: 'text.disabled',
          textDecoration: 'line-through',
        }),
      }}
      key={taskId}
    >
      {/* <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} disabled={status !== ORDER_STATUS.IN_PROGRESS} />}
        label={<Typography variant="body2" fontSize={textSize}>{productName} <span style={{color: "darkgrey"}}> ( x {quantity} )</span> </Typography>}
        sx={{ flexGrow: 1, m: 0}}
      /> */}

      <Typography variant="body2" fontSize={textSize}>- {productName} <span style={{color: "darkgrey"}}> ( x {quantity} )</span> </Typography>
      {specifics && <Typography variant="caption" color="text.secondary">&emsp;[ {specifics} ]</Typography>}
      {/* <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton> */}

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleMarkComplete}>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
          Mark Complete
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleShare}>
          <Iconify icon={'eva:share-fill'} sx={{ mr: 2 }} />
          Share
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}
    </Stack>
  );
}
