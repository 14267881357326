import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}
  
function getGroupedProducts(products) {
  const groups = {}
  for (const productObj of products) {
    const { product, quantity } = productObj;
    const { id: productId, product_name: productName, price } = product;

    if (!(productId in groups)) {
      groups[productId] = {
        quantity: 0,
        productName: productName,
        priceUnit: price,
        total: 0
      }
    }

    groups[productId].quantity += quantity;
    groups[productId].total += productObj.product.price * quantity;
  }
    

  return Object.keys(groups).map(key => groups[key]);
}

export default function BillTable({bill}) {
  const groupedProducts = getGroupedProducts(bill.products);
  const billDiscount = bill.discount || 0;

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Quantity</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Price p/u</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedProducts.map((row) => {
            const { quantity, productName, priceUnit, total } = row;

            return (
              <TableRow
                key={row.productName}
              >
                <TableCell component="th" scope="row">
                  {quantity}
                </TableCell>
                <TableCell align='left'>{productName}</TableCell>
                <TableCell align='left'>{priceUnit}</TableCell>
                <TableCell align='left'><b>{ccyFormat(total)}</b></TableCell>
              </TableRow>
            )})}
        
         {billDiscount > 0 && <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={2} >Subtotal</TableCell>
            <TableCell align="left"><b>{ccyFormat(bill.total + billDiscount)}</b></TableCell>
          </TableRow>}
          {billDiscount > 0 && <TableRow>
            <TableCell colSpan={2}>Descuento</TableCell>
            <TableCell align="left"><b>-${ccyFormat(bill.discount)}</b></TableCell>
          </TableRow>}
          <TableRow>
            {billDiscount > 0 || <TableCell rowSpan={3} /> }
            <TableCell colSpan={2} sx={{backgroundColor: "primary.darker"}} >Total</TableCell>
            <TableCell align="left" sx={{backgroundColor: "primary.darker"}}><b>${ccyFormat(bill.total)}</b></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}