import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
import CustomerPage from './pages/CustomerPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import SecondScreen from './pages/SecondScreen';
import ReferralForm from './pages/ReferralForm';
import MenuPage from './pages/MenuPage';
import OrdersPage from './pages/OrdersPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { AuthContext } from './sections/auth/AuthProvider';
import { useContext } from 'react';

// ----------------------------------------------------------------------

export default function Router() {
  const { isLoggedIn, user } = useContext(AuthContext);
  
  function isReaderOnly(user) {
    return user && !user.is_admin && !user.is_staff && user.is_reader_only;
  }
  
  localStorage.setItem('fetchOrdersAutomatically', false);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: (user && user.is_admin) ? <DashboardAppPage /> : <Navigate to="/dashboard/orders" replace /> },
        { path: 'customers', element: (!isReaderOnly(user)) ? <CustomerPage /> : <Navigate to="/dashboard/orders" replace />},
        { path: 'menu', element: (!isReaderOnly(user)) ? <MenuPage /> : <Navigate to="/dashboard/orders" replace />},
        { path: 'orders', element: <OrdersPage /> },
        // { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'orders',
      element: <OrdersPage styles={{padding: '80px 0px 80px 0px', minWidth: '90vw' }} fullScreen={true} />,
    },
    {
      path: 'second-screen',
      element: <SecondScreen />,
    },
    {
      path: 'referral',
      element: <ReferralForm />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
