import axios from 'axios';
import { useState, useContext } from 'react';
import { AuthContext } from '../../sections/auth/AuthProvider';
import { isNil, isEmpty } from 'lodash';
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    TextField,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Alert,
    Snackbar,
    Card,
    CardContent,
    Typography,
    CardActions,
    Box,
    FormControlLabel,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
} from '@mui/material';

import Iconify from '../../components/iconify';


import CustomerBadgeStatus from './CustomerBadgeStatus';
import BillTable from './BillTable';
import APIClient from '../../utils/APIClient';
import CustomerStatus from './CustomerStatus';

export default function ScanQrCodeDialog({ open, setOpen }) {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [customer, setCustomer] = useState([]);
    const [bill, setBill] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isMarkAssitence, setIsMarkAssitence] = useState(true);
    const [machineGunMode, setMachineGunMode] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('CASH');
    const { token, user } = useContext(AuthContext);

    const apiClient = new APIClient(token);

    const handleClose = () => {
        setOpen(false);
        setCustomer([]);
        setQrCode('');
        setErrorMsg(null);
    };

    const snackBarClose = () => {
        setOpenSnackBar(false);
    };

    async function getBillFromQrCode(qrCode) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`${apiUrl}/api/customer/bill?qr-id=${qrCode}`, opt);
        return response.data;
    }

    function getIsCustomerToPay(customerArr) {
        return (
            customerArr.length > 0 && 
            ([CustomerStatus.TO_PAY, CustomerStatus.IN_EVENT].includes(customerArr[0].status_in_event))
        );
    }

    async function markAssitence(qrCode) {
        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const data = {
            qr_id: qrCode,
        };

        return await axios.post(`${apiUrl}/api/customer/attended`, data, opt);
    }


    function resetDelay() {
        const delay = (machineGunMode) ? 1000 : 3000;
        setTimeout(() => {
            resetState();
        }, delay);
    }

    function successFulActionFeedback(msg) {
        setSnackBarMsg(msg);
        setOpenSnackBar(true);
        resetDelay();
    }

    function setLocalStorageStateForSecondScreen(customerArr, bill = {}, billPayed = false) {
        // Special function for the second screen where
        // the customer can see some welcoming message or the bill
        const customer =  (customerArr.length > 0) ? customerArr[0] : {};

        localStorage.setItem('customer', JSON.stringify(customer));
        localStorage.setItem('bill', JSON.stringify(bill));
        localStorage.setItem('billPayed', billPayed);
    }

    async function searchCustomer(qrCode) {
        const response = await getCustomerData(qrCode);
        const customerArr = response.data;
        setCustomer(customerArr);
        const isCustomerToPay = getIsCustomerToPay(customerArr);

        
        if (isCustomerToPay) {
            const bill = await getBillFromQrCode(qrCode);
            setBill(bill);
            setLocalStorageStateForSecondScreen(customerArr, bill);
        }
        
        // Mark assistence
        if (isMarkAssitence && !isCustomerToPay) {
            setLocalStorageStateForSecondScreen(customerArr, {});
            await markAssitence(qrCode);
            successFulActionFeedback('Mark as attended Successfully');
        }

    };

    const getCustomerData = async (qrCode) => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        return await axios.get(`${apiUrl}/api/customer/?qr-id=${qrCode}`, opt);
    }

    function resetState() {
        setCustomer([]);
        setErrorMsg(null);
        setQrCode('');
        setBill(null);
        setPaymentMethod('CASH');
    }

    // Not proud of this jeje
    // TODO: FIx API Error Responses
    function errorToStringMsg(error) {
        if (error.response) {
            return Object.keys(error.response.data).map((key) => error.response.data[key]);
        }

        return error.toString();
    }

    async function handleQrCodeInputChanged(evt) {
        const qrVal = evt.target.value;
        resetState();
        setQrCode(qrVal.toUpperCase());
        if (qrVal.length < 5) {
            return;
        }

        // Call API
        try {
            await searchCustomer(qrVal);
        } catch (error) {
            const errMsg = errorToStringMsg(error);
            setErrorMsg(errMsg);
            console.error(error);
        }
    };

    async function handleMarkAssistenceBtnClick(qrId) {
        try {
            await markAssitence(qrId);
            successFulActionFeedback('Mark as attended Successfully');
        } catch (error) {
            const errMsg = errorToStringMsg(error);
            setErrorMsg(errMsg);
            console.error(error);
        }
    }

    async function _payBill(qrId) {
        const data = {
            qr_id: qrId,
            payment_method: paymentMethod,
        };

        return await apiClient.post("/api/customer/pay-bill", data);
    }

    async function handlePayBillBtnClick(qrId) {
        try {
            // eslint-disable-next-line no-restricted-globals
            const question = confirm(`Confirm you received the payment of $${bill.total}`);
            if (!question) {
                return;
            }

            await _payBill(qrId);
            resetState();
            successFulActionFeedback('Marked as PAYED! $$$$');
            setLocalStorageStateForSecondScreen([], {}, true);
        } catch (error) {
            const errMsg = errorToStringMsg(error);
            setErrorMsg(errMsg);
            console.error(error);
        }
    }

    function NoOrdersText() {
        return (
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>No Orders</Typography>
        )
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
                <DialogTitle>
                    Scan QR Code
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Scan or type the QR Code
                    </DialogContentText>
                    {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <Stack
                        noValidate
                        autoComplete="off"
                        mt={3}
                        mb={5}
                    >
                        <TextField
                            margin="dense"
                            label="QR CODE ID"
                            type="text"
                            value={qrCode}
                            onChange={handleQrCodeInputChanged}
                            fullWidth
                            autoFocus
                        />
                        <Stack direction="row" spacing={2}>
                            <FormControlLabel
                                control={<Checkbox checked={isMarkAssitence} onChange={(evt) => setIsMarkAssitence(evt.target.checked)} size="small" />}
                                label={<Typography variant='caption' color="text.secondary">Mark Asistence Automatically</Typography>}
                            />
                            <FormControlLabel
                                control={<Checkbox size="small" checked={machineGunMode} onChange={(evt) => setMachineGunMode(evt.target.checked)} />}
                                label={<Typography variant='caption' color="text.secondary">Machine Gun Mode</Typography>}
                            />
                        </Stack>
                    </Stack>

                    {(isEmpty(customer) && qrCode.length > 4) && <Stack justifyContent="center" direction="row">
                        <Typography variant='h4' color="text.secondary">{"QR ID NOT FOUND :("}</Typography>
                    </Stack>}

                    {customer.map((row) => {
                        const { id, full_name: fullName, status_in_event: statusInEvent } = row;
                        return (
                            <Card sx={{ minWidth: 275, borderColor: "primary.lighter" }} variant="outlined" key={id}>
                                <CardContent>
                                    <Stack direction="row" spacing={1} mb={3}>
                                        <Typography variant="h5" component="div">
                                            {fullName}
                                        </Typography>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <CustomerBadgeStatus status={statusInEvent}></CustomerBadgeStatus>
                                        </Box>
                                    </Stack>

                                    {
                                        isNil(bill) ? <NoOrdersText /> : (<BillTable bill={bill} />)
                                    }
                                    
                                </CardContent>
                                <CardActions sx={{ justifyContent: 'center'}}>
                                    {
                                        (!isMarkAssitence && customer.length > 0 && customer[0].status_in_event !== "TO_PAY") &&
                                        <Button startIcon={<Iconify icon="material-symbols:check-small" />} size="small" variant='outlined' onClick={() => handleMarkAssistenceBtnClick(customer[0].qr_id)}>
                                            Mark Assistence
                                        </Button>
                                    }
                                    {(getIsCustomerToPay(customer) && user.is_admin) && (
                                        <Stack direction="row" spacing={2}>
                                            <FormControl>
                                                <Select
                                                    value={paymentMethod}
                                                    onChange={(evt) => setPaymentMethod(evt.target.value)}
                                                >
                                                <MenuItem value="CASH">CASH</MenuItem>
                                                <MenuItem value="CARD">CARD</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Button startIcon={<Iconify icon="solar:bill-check-bold" />} onClick={() => handlePayBillBtnClick(customer[0].qr_id)} size="small" variant='outlined'>
                                                Pay Bill
                                            </Button>
                                        </Stack>
                                    )
                                    }
                                </CardActions>
                            </Card>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} >Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnackBar} onClose={snackBarClose}  autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
                <Alert onClose={snackBarClose} severity="success" sx={{ width: '100%' }}>
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>

    )
}