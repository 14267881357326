import axios from "axios";

export default class APIClient {
    constructor(token = null) {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.token = token;
    }

    getHeaders() {
        const options = {};
        if (this.token != null) {
            options.headers = {
                "Authorization": `Bearer ${this.token}`,
            };
        }

        return options;
    }

    async get(path) {
        return axios.get(`${this.baseURL}${path}`, this.getHeaders())
    }

    async post(path, data) {
        return axios.post(`${this.baseURL}${path}`, data, this.getHeaders())
    }

    async put(path, data) {
        return axios.put(`${this.baseURL}${path}`, data, this.getHeaders())
    }
}