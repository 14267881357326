export const ORDER_STATUS = Object.freeze({
    INCOMMING: 'INCOMMING', // Encompass PENDING and IN_PROGRESS in the backend.
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
});

export const ORDER_STATUS_LABEL = Object.freeze({
    PENDING: 'Pendiente',
    IN_PROGRESS: 'En progreso',
    COMPLETED: 'Completado',
    DELIVERED: 'Entregado',
    CANCELLED: 'Cancelado',
});