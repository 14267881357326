import { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';

// component
import Iconify from '../../../components/iconify';
import { OrderContext } from 'src/pages/OrderContext';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    zIndex: 999,
    display: 'flex',
    cursor: 'pointer',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: theme.spacing(4),
    left: '50%',
    transform: 'translateX(-50%)',
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.25),
    color: theme.palette.text.primary,
    width: '100%',

}));

const alignRightStyle = {
    alignItems: 'right',
    justifyContent: 'right',
};

const mobileBtnStyle = {
    width: '70%',
}
// ----------------------------------------------------------------------

export default function AddOrderButtonWidget({onClickHandler}) {
  const { orderProducts } = useContext(OrderContext);
  const isMobile = useResponsive('down', 'sm');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!isEmpty(orderProducts));
  }, [orderProducts])


  if (show) {
      return (
        <StyledRoot sx={(isMobile) ? {} : alignRightStyle}>
            <Button onClick={onClickHandler} variant="contained" size='large' color="success" sx={isMobile && mobileBtnStyle} startIcon={<Iconify color="white" width={28} icon="material-symbols:order-approve" />}>
                <Typography variant='h5' color="white">Agregar Orden</Typography>
            </Button>
        </StyledRoot>
      );
  }
}
