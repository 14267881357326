import { alpha } from '@mui/material/styles';

const GREY = {
  50: '#F2F2F2',
  100: '#E0E0E0',
  200: '#BDBDBD',
  300: '#9E9E9E',
  400: '#757575',
  500: '#616161',
  600: '#424242',
  700: '#303030',
  800: '#26292A',
  900: '#2C3031',
};

const PRIMARY = {
  lighter: '#9FC9FF',
  light: '#5A8EFF',
  main: '#1565C0',
  dark: '#0D47A1',
  darker: '#002171',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#B8E2FF',
  light: '#4DABF5',
  main: '#0277BD',
  dark: '#015384',
  darker: '#002040',
  contrastText: '#fff',
};

const TERTIARY = {
  lighter: '#D1C4E9',
  light: '#9C27B0',
  main: '#673AB7',
  dark: '#9C27B0',
  darker: '#311B92',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#B3E5FC',
  light: '#4FC3F7',
  main: '#03A9F4',
  dark: '#4FC3F7',
  darker: '#01579B',
  contrastText: '#fff',
};


const SUCCESS = {
  lighter: '#D1F5BE',
  light: '#7BE388',
  main: '#43A047',
  dark: '#7BE388',
  darker: '#1B5E20',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFE082',
  light: '#FFB74D',
  main: '#FF9800',
  dark: '#F57C00',
  darker: '#E65100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFCDD2',
  light: '#E57373',
  main: '#F44336',
  dark: '#D32F2F',
  darker: '#B71C1C',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  tertiary: TERTIARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: '#fff',
    secondary: GREY[300],
    disabled: GREY[500],
  },
  background: {
    paper: GREY[800],
    default: GREY[900],
    neutral: GREY[700],
  },
  action: {
    active: '#fff',
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
