import { useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from 'src/sections/auth/AuthProvider';
import {
    Dialog,
    DialogTitle,
    DialogContentText,
    TextField,
    DialogContent,
    DialogActions,
    Button,
    Stack,
    Alert,
    Grid
} from '@mui/material';

export default function NewCustomerDialogForm({open, setOpen}) {

    const [errorMsg, setErrorMsg] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [areaCode, setAreaCode] = useState('+52');
    const { token } = useContext(AuthContext);


    const handleClose = () => {
        setOpen(false);
    };

    const constructErrorMessageFromErrorDict = (errDict) => {
        let msg = '';
        for (const key in errDict) {
            msg += `- ${key}: ${errDict[key]} \n`;
        }

        return msg;
    }

    const cleanFields = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setErrorMsg(null);
        setAreaCode('+52');
    };

    const handleSave = async () => {
        if (!firstName || !lastName || !phoneNumber || areaCode.length < 2) {
            setErrorMsg('Please fill all the fields');
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const opt = {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        };

        const url = `${apiUrl}/api/customer/`;
        console.log(phoneNumber.replace(/\s+/g, ''));
        const data = {
            first_name: firstName,
            last_name: lastName,
            phone_number: `${areaCode}${phoneNumber.replace(/\s+/g, '')}`,
        };

        try {
            await axios.post(url, data, opt);
            cleanFields();
            setOpen(false);
        } catch (err) {
            if (err.response) {
                const cleanedError = constructErrorMessageFromErrorDict(err.response.data);
                setErrorMsg(cleanedError);
                return;
            }

            setErrorMsg(err.toString());
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            document.getElementById('save-btn').click();
        }
    };

    const handleAreaCodeChange = (event) => {
        const areaCodeVal = event.target.value;
        setAreaCode(areaCodeVal);
        if (!areaCodeVal.startsWith('+')) {
          setAreaCode("+" + areaCodeVal);
          return;
        }
      };  

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>New Customer</DialogTitle>
            <DialogContent>
            <DialogContentText mb={2}>
                Adding a new Customer will invite him automatically to the current event.
            </DialogContentText>
            {errorMsg && <Alert severity="error" sx={{marginBottom: "8px"}}>{errorMsg}</Alert>}
            <Stack
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                direction="row"
                noValidate
                autoComplete="off"
            >
                <TextField
                    margin="dense"
                    label="First Name"
                    type="text"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    autoFocus
                />

                <TextField
                    margin="dense"
                    label="Last Name"
                    type="text"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                />
                 
            </Stack>
            <Grid container spacing={2} p={0} mt={1}>
                <Grid item xs={3}>
                <TextField
                  label="Area Code"
                  placeholder='+123'
                  type="text"
                  fullWidth
                  value={areaCode}
                  onChange={handleAreaCodeChange}
                  />
                </Grid>

                <Grid item xs>
                <TextField
                    label="Phone Number"
                    placeholder='55 1234 5678'
                    type="text"
                    fullWidth
                    lg={12}
                    value={phoneNumber}
                    onKeyPress={handleKeyPress}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                />
                </Grid>
                  
              </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSave}  id="save-btn">Save</Button>
            </DialogActions>
        </Dialog>
    )
}