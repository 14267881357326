import { sentenceCase } from 'change-case';
import Label from '../label';

const CUSTOMER_STATUS = {
    IN_EVENT: 'IN_EVENT',
    NOT_ARRIVED: 'NOT_ARRIVED',
    BANNED: 'BANNED',
    DEPARTED: 'DEPARTED',
    TO_PAY: 'TO_PAY',
    NOT_INVITED: 'NOT_INVITED',
    INVITATION_PENDING: 'INVITATION_PENDING',
};

const getStatusColor = (status) => {
    switch (status) {
        case 'IN_EVENT':
            return 'success';
        case 'NOT_ARRIVED':
            return 'info';
        case 'BANNED':
            return 'error';
        case 'DEPARTED':
            return 'default';
        case 'TO_PAY':
            return 'warning';
        case 'NOT_INVITED':
            return 'default';
        case 'INVITATION_PENDING':
            return 'warning';
        default:
            return 'tertiary';
    }
  };


export default function CustomerBadgeStatus({status}) {
    const allStatus = Object.values(CUSTOMER_STATUS);
    const cleanedSentence = (allStatus.includes(status)) ? sentenceCase(status) : status;
    
    return (
        <Label color={getStatusColor(status)} sx={{width: 'fit-content'}}>
            {cleanedSentence}
        </Label>
    )
}