import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useState, useContext, useEffect } from 'react';
// @mui
import { Container } from '@mui/material';
// components
import {ProductList, ProductCartWidget, AddOrderButtonWidget, OrderScanQrDialog } from '../sections/@dashboard/products';
// mock
// import PRODUCTS from '../_mock/products';
import { AuthContext } from '../sections/auth/AuthProvider';
import { OrderProvider } from './OrderContext';
// ----------------------------------------------------------------------

export default function MenuPage() {
  const { token } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  
  function getProducts(token) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const options = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    };

    axios.get(`${apiUrl}/api/products`, options).then((response) => {
      const products = response.data || [];
      setProducts(products);
    }).catch((error) => {
      alert("Error conectandose con el Servidor. Haz Logout y login de nuevo.")
      console.log(error);
    });
  }

  function handleClickAddOrder() {
    setOpenQrDialog(true);
  }

  // This to provide the DIalog with the products names 
  // And make a final order view for the user.
  function prductsToSearchableDict(products) {
    const groupedProducts = products.reduce((acc, product) => {
      const { id } = product;
      if (!acc[id]) {
        acc[id] = product;
      }  
      return acc;
    }, {});

    return groupedProducts;
  }

  useEffect(() => {
    getProducts(token);
  }, [token]);

  return (
    <OrderProvider>
      <Helmet>
        <title> Dashboard: Menu </title>
      </Helmet>

      <Container>
         {/* TODO: ADD TABS */}
        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}

        <ProductList products={products} />

        <AddOrderButtonWidget onClickHandler={handleClickAddOrder} />
        <ProductCartWidget />
        <OrderScanQrDialog open={openQrDialog} setOpen={setOpenQrDialog} groupedProducts={prductsToSearchableDict(products)} />
      </Container>
    </OrderProvider>
  );
}
