import { useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box,
  Card,
  Button,
  Typography,
  Stack,
  CardContent,
  CardActions,
  TextField,
  Link,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { OrderContext } from 'src/pages/OrderContext';
import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'scale-down',
  position: 'absolute',
});

const priceTypoStyle = {
  padding: '2px 5px 2px 5px',
  position: 'relative',
  float: 'right',
  background: 'rgba(0, 0, 0, 0.6)',
  width: 'fit-content',
  mb: '3px',
}

const spinnerBtnStyle = {
  padding: '0px',
  minWidth: '45px',
  minHeight: '40px',
}
// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

const DEFAULT_IMG = '/assets/images/products/no-product-image.jpg';

function NumberInput({addHandler, substractHandler, value}) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button variant="contained" sx={spinnerBtnStyle} size="small" onClick={substractHandler}>—</Button>
        <TextField size="small" value={value} sx={{width: '60px'}}  InputProps={{readOnly: true}}/>
      <Button variant="contained" sx={spinnerBtnStyle} size="small" onClick={addHandler}>＋</Button>
    </Stack>
  );
}

export default function ShopProductCard({ product }) {
  const { product_name: name, is_active: isActive, description, photo, price} = product;
  const { orderProducts, addProduct, substractProduct, addCustomerSpecifics} = useContext(OrderContext);
  const [count, setCount] = useState(0);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [customerSpecifics, setCustomerSpecifics] = useState("");
  const isMobile = useResponsive('down', 'sm');

  useEffect(() => {
    const currProductCount = (product.id in orderProducts) ? orderProducts[product.id] : 0;
    setCount(currProductCount);
  }, [orderProducts]);

  function handleAddNumberInput() {
    const { id } = product;
    // Check if count is zero
    addProduct(id);
  }

  function handleSubstractNumberInput() {
    const { id } = product;

    // Check if count is zero
    substractProduct(id);
  }


  function handleAddBtn() {
    const { id } = product;
    addProduct(id);
  }

  function handleSaveDescription() {
    const { id } = product;
    addCustomerSpecifics(id, customerSpecifics);
    setOpenDescriptionDialog(false);
  }


  const cardHeight = isMobile ? '300px' : '340px';

  return (
    <>
      <Card sx={{height: cardHeight}}>
        <Box sx={{ pt: '40%', position: 'relative' }}>
          {!isActive && (
            <Label
              variant="filled"
              color={'default'}
              sx={{
                zIndex: 9,
                top: 16,
                right: 16,
                position: 'absolute',
                textTransform: 'uppercase',
              }}
            >
              {"AGOTADO"}
            </Label>
          )}
          <StyledProductImg alt={name} src={photo || DEFAULT_IMG} />
          <Stack justifyContent='right' direction="row" sx={{pr: 1}}>
            <Typography variant="subtitle1" sx={priceTypoStyle}>
              {fCurrency(price)}
            </Typography>
          </Stack>
        </Box>

        <Stack sx={{ p: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
          <CardContent sx={{ p: 0, mb: 1, height: '70px'}}>
            <Typography variant="body2" color="text.secondary">
              {description || name}
            </Typography>
          </CardContent>
          
          <CardActions sx={{justifyContent: 'center', mt: 0}}>
            {count === 0 && <Button size="small" variant='contained' disabled={!isActive} fullWidth onClick={handleAddBtn}>Agregar</Button>}
            {count > 0 && (
              <Stack textAlign="center" spacing={1}>
                <NumberInput value={count} addHandler={handleAddNumberInput} substractHandler={handleSubstractNumberInput} />
                <Link href="#" variant="body2" onClick={() => setOpenDescriptionDialog(true)}>Añadir Descripción</Link>
              </Stack>
            )}
          </CardActions>
        </Stack>
      
      </Card>
      <Dialog open={openDescriptionDialog} onClose={() => {setOpenDescriptionDialog(false)}}>
        <DialogTitle>Añadir Descripción al producto</DialogTitle>
        <DialogContent>
          <TextField
            label="Descripción"
            multiline
            rows={4}
            value={customerSpecifics}
            onChange={(e) => {setCustomerSpecifics(e.target.value)}}
            fullWidth
            autoFocus
            focused
          />
        </DialogContent>
        <DialogActions >
          <Button onClick={() => {setOpenDescriptionDialog(false)}}>Cancelar</Button>
          <Button onClick={handleSaveDescription}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
