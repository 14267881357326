import { useEffect, useContext, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../sections/auth/AuthProvider';
import {Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    TextField,
    Stack,
    FormControlLabel,
    Checkbox,
    Typography
} from '@mui/material';

import APIClient from '../../utils/APIClient';


export default function QrCodeImageDialog({qrId, open, setOpen, invitationSent, setInvitationSent}) {
  const { token } = useContext(AuthContext);
  const [qrBase64Img, setQrBase64Img] = useState(null);
  const [customer, setCustomer] = useState({});
  const urlRef = useRef(null);

  const apiCLient = new APIClient(token);

  const getQrData = useCallback(() => {
   
    const url = `/api/customer/qr?qr-id=${qrId}`;
    apiCLient.get(url).then((res) => {
      setQrBase64Img(res.data.qr_image_str);
      setCustomer(res.data.customer);
    })
    .catch((err) => {
      alert("Error getting QR Code", err);
      console.error("QR Dialog Err", err);
    })
  }, [qrId, token]);

  const handleInvitationSent = async (e) => {
    const checked = e.target.checked;
    const data = {invitation_sent: checked, qr_id: qrId };
    try {
      await apiCLient.put('/api/customer/invitation-sent', data);
      setInvitationSent(checked);
    } catch (err) {
      console.error("Error updating invitation sent", err);
      alert("Error updating invitation sent");
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyUrl = () => {
    if (urlRef.current) {
        navigator.clipboard.writeText(urlRef.current.value);
    }
  };    

  useEffect(() => {
    setQrBase64Img(null);
    if (qrId) {
        getQrData();
    }
  }, [getQrData, qrId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
          QR CODE | {qrId}
      </DialogTitle>
      <DialogContent>
          <Stack>
            <Stack mb={5}>
              <img src={`data:image/jpeg;base64,${qrBase64Img}`} alt={qrId}/>
            </Stack>
              <TextField
                  inputRef={urlRef}
                  value={`https://${window.location.host}/referral/?referral-code=${customer.referral_code}`}
                  label="Referral URL"
                  disabled
                  fullWidth
                  InputProps={{
                      endAdornment: (
                          <Button onClick={handleCopyUrl}>Copy</Button>
                      ),
                  }}
              />
              <FormControlLabel
                control={<Checkbox checked={invitationSent} onChange={ handleInvitationSent } size="small" />}
                label={ <Typography variant='caption' color="text.secondary">Invitation sent</Typography> }
              />
          </Stack>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}