import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogContent, DialogTitle, Grid, Typography, TextField, DialogActions, Button} from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  const groupedProducts = products.reduce((acc, product) => {
    const { category } = product;
    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(product);
    return acc;
  }, {});

  return (
    <Grid container spacing={3} {...other}>
      {Object.keys(groupedProducts).sort().map((category) => {
        const products = groupedProducts[category];
        return (
          <Grid key={category} item xs={12} sx={{ mb: 5 }}>
            <Typography variant="h3" sx={{ mb: 3 }}>
              {category}
            </Typography>
            <Grid container spacing={3}>
              {products.map((product) => (
                <Grid key={product.id} item xs={6} sm={4} md={3}>
                  <ShopProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
