import axios from 'axios';

import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useContext, useCallback, useRef } from 'react';
// @mui
import {
  Stack,
  MenuItem,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Grid,
  Divider,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton

} from '@mui/material';
// sections
import { AuthContext } from '../sections/auth/AuthProvider';
import  { OrderTasksCard, ORDER_STATUS} from '../sections/@dashboard/orders/';
import { isEmpty } from 'lodash';
import Iconify from 'src/components/iconify/Iconify';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import './glowyCard.css';

class OrdersSet {
  // Class to store orderes withput duplicates.
  constructor() {
    this.ordersById = {};
  }

  addOrder(order) {
    this.ordersById[order.id] = order;
  }

  addListOfOrders(orders) {
    orders.forEach((order) => this.addOrder(order));
  }

  getOrdersArray() {
    return Object.values(this.ordersById);
  }

  removeOrder(orderId) {
    delete this.ordersById[orderId];
  }
}

function OrdersAccordion({ status, getOrdersByStatus, updateOrderStatus, categoryFilterStr }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [ordersSet, setOrdersSet] = useState(new OrdersSet());
  const [orders, setOrders] = useState([]);
  const refreshBtnRef = useRef(null);
  const audioRef = useRef(null);
  const [ fetchOrdersAutomatically, setFetchOrdersAutomatically ] = useState(false);

  const loadItemsByStatus = useCallback(async (status, excludeOrdersIds = []) => {
    try {
      const res = await getOrdersByStatus(status, categoryFilterStr, excludeOrdersIds);
      ordersSet.addListOfOrders(res.data);
      setOrders(ordersSet.getOrdersArray());
    } catch (error) {
      console.log("OrdersAccordion => ", error);
    }
  }, [getOrdersByStatus, ordersSet, categoryFilterStr]);
  
  function getReadableStatus(status) {
    switch (status) {
      case ORDER_STATUS.PENDING:
        return 'Pendientes';
      case ORDER_STATUS.IN_PROGRESS:
        return 'en Progreso';
      case ORDER_STATUS.COMPLETED:
        return 'Completadas';
      case ORDER_STATUS.DELIVERED:
          return 'Entregadas';
      case ORDER_STATUS.CANCELLED:
        return 'Canceladas';
      case ORDER_STATUS.INCOMMING:
          return 'Entrantes';
      default:
        return 'Pendientes';
    }
  }

  function removeOrderFromOrderList(orderId) {
    ordersSet.removeOrder(orderId);
    setOrders(ordersSet.getOrdersArray());
  }

  async function cancelOrder(orderId) {
    try {
      const question = window.confirm(`¿Está seguro que desea cancelar la orden #${orderId}?`);
  
      if (question) {
        await updateOrderStatus(orderId, ORDER_STATUS.CANCELLED);
        removeOrderFromOrderList(orderId);
      }

    } catch (error) {
      console.log("cancelOrderHandler => ", error);
    }
  }

  async function completeOrder(orderId) {
    try {
      await updateOrderStatus(orderId, ORDER_STATUS.COMPLETED);
      removeOrderFromOrderList(orderId);
    } catch (error) {
      console.log("cancelOrderHandler => ", error);
    }
  }

  async function deliverOrder(orderId) {
    try {
      await updateOrderStatus(orderId, ORDER_STATUS.DELIVERED);
      removeOrderFromOrderList(orderId);
    } catch (error) {
      console.log("cancelOrderHandler => ", error);
    }
  }

  function playNotificationAudio() {
    audioRef.current.play();
  }

  const smartRefreshOrders = useCallback(async (ordersIds) => {
    try {
      const res = await getOrdersByStatus(status, categoryFilterStr, ordersIds);
      const newOrders = res.data;

      if (newOrders.length > 0) {
        ordersSet.addListOfOrders(newOrders);
        setOrders(ordersSet.getOrdersArray());
        
        if (newOrders.length > 0 && status === ORDER_STATUS.INCOMMING) {
          playNotificationAudio();
        }
      }
    } catch (error) {
      console.error("smartRefreshOrders => ", error);
    }
  }, [categoryFilterStr, getOrdersByStatus, status, ordersSet]);

  const handleRefresh = useCallback(async (evt) => {
    evt.stopPropagation();
    // FIXME: Temporary fix. DELIVERED Orders always need to be hard refreshed.
    const orderIds = (status !== ORDER_STATUS.DELIVERED) ? orders.map((order) => order.id) : [];
    await smartRefreshOrders(orderIds);
  }, [orders, smartRefreshOrders, status]);

  useEffect(() => {
    if (fetchOrdersAutomatically) {
      const intervalId = setInterval(async () => {
        refreshBtnRef.current.style.color = 'orange';
        refreshBtnRef.current.click();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [fetchOrdersAutomatically, smartRefreshOrders, orders, status, categoryFilterStr]);

  const RotatingIconButton = styled(IconButton)(() => ({
    padding: '0px',
  }));


  return (
    <Accordion
        expanded={isExpanded}
        onChange={() => {
          setIsExpanded((prev) => !prev);

          if (!isExpanded) {
            loadItemsByStatus(status);
          } else {
            setOrdersSet(new OrdersSet());
            setOrders([]);
          }
        }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<Iconify icon="material-symbols:keyboard-arrow-down" />} aria-controls="stack-content" id="stack-header">
          <Stack spacing={0}>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4">Ordenes {getReadableStatus(status)}</Typography>
              {isExpanded && 
                <RotatingIconButton 
                  sx={{ padding: '0px', ':active': { color: 'orange' }}}
                  color="info"
                  onClick={handleRefresh}
                  ref={refreshBtnRef}
                >
                <Iconify icon="mdi:refresh-circle"  width={30} />
              </RotatingIconButton>
              }
            </Stack>
            {isExpanded && 
              <FormGroup onClick={(event) => event.stopPropagation()}>
                <FormControlLabel
                  control={<Checkbox checked={fetchOrdersAutomatically}
                  onChange={(e) => {
                    e.stopPropagation();
                    setFetchOrdersAutomatically(e.target.checked);
                  }} />} 
                  label={<Typography variant='body2' color="grey"> Fetch Orders Automatically</Typography>} 
                />
              </FormGroup>
            }
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" sx={{overflowX: 'auto'}} spacing={2} p={2}>
          {isEmpty(orders) && <Typography variant="h5" color="grey" gutterBottom>No hay ordenes {getReadableStatus(status)}</Typography>}
          <>
            {/* I NEED THE DELIVERED ORDERS BACKWARDS!!! */}
            {((status === ORDER_STATUS.DELIVERED) ? [...orders].reverse() : orders).map(order => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={order.id}>
                  <OrderTasksCard
                    order={order}
                    cancelOrder={cancelOrder}
                    completeOrder={completeOrder}
                    updateOrderStatus={updateOrderStatus}
                    deliverOrder={deliverOrder}
                    key={order.id}         
                  />
                </Grid>
              );
            })}
          </>
        </Stack>
        </AccordionDetails>

        <audio ref={audioRef} src="/assets/audio/new_order.mp3" /> 
      </Accordion>
  )
}

export default function OrdersPage({styles, fullScreen }) {
  const [ categoryFilterStr, setCategoryFilterStr ] = useState('');

  const { token } = useContext(AuthContext);

  async function updateOrderStatus(orderId, status) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const options = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    };

    const data = {
      order_id: orderId,
      status,
    };

    return await axios.put(`${apiUrl}/api/orders/update-status/`, data, options);
  }

  async function getOrdersByStatus(status, categoryFilterStr, excludeOrdersIds = []) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const options = {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
      params: {
        status,
        excludeOrdersIds: excludeOrdersIds.join(","),
      }
    }

    if (categoryFilterStr !== "") {
      options.params = {
        category: categoryFilterStr,
        ...options.params
      }
    }

    return axios.get(`${apiUrl}/api/orders/`, options);
  }
  
  return (
    <div>
      <Helmet>
        <title> Ordenes </title>
      </Helmet>

      <Container sx={{ paddingLeft: "0px", ...styles}}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Stack>
            <Typography variant="h3" gutterBottom>
              Ordenes
            </Typography>
            {fullScreen || <Link to="/orders" style={{color: "blue"}} target='_blank'>
              <Typography variant="bosy1" gutterBottom>
                Full Screen
              </Typography>
            </Link>}
          </Stack>
          <Stack direction="row" width={150}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Filtrar</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Filtro"
                value={categoryFilterStr}
                onChange={(e) => { setCategoryFilterStr(e.target.value)} }
              >
                <MenuItem value="Cocina">Cocina</MenuItem>
                <MenuItem value={"Bebidas"}>Bebidas</MenuItem>
                <MenuItem value={""}>Todas</MenuItem>
              </Select>
            </FormControl>
          </Stack>
            {/* <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={fetchOrdersAutomatically}
                onChange={(e) => setFetchOrdersAutomatically(e.target.checked)} />} 
                label={<Typography variant='body2' color="grey"> Fetch Orders Automatically</Typography>} 
              />
            </FormGroup> */}
          </Stack>

        <Stack spacing={2}>

          <OrdersAccordion status={ ORDER_STATUS.INCOMMING } updateOrderStatus={updateOrderStatus} getOrdersByStatus={getOrdersByStatus} categoryFilterStr={categoryFilterStr}/>
          <Divider />
          <OrdersAccordion status={ ORDER_STATUS.COMPLETED } updateOrderStatus={updateOrderStatus} getOrdersByStatus={getOrdersByStatus} categoryFilterStr={categoryFilterStr}/>
          <Divider />
          <OrdersAccordion status={ ORDER_STATUS.DELIVERED } updateOrderStatus={updateOrderStatus} getOrdersByStatus={getOrdersByStatus} categoryFilterStr={categoryFilterStr}/>
          <Divider />
          <OrdersAccordion status={ ORDER_STATUS.CANCELLED } updateOrderStatus={updateOrderStatus} getOrdersByStatus={getOrdersByStatus} categoryFilterStr={categoryFilterStr}/>


        </Stack>        
      </Container>
    </div>
  );
}
